.add-folder-wrapper {
    // padding: 16px;
}

.add-folder-title {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 18px;
}

.folder-input-wrapper {
    min-width: 350px;
}

.folder-input {
    padding: 18.5px 14px;
    width: 100%;
}

.add-folder-action-bar {
    width: 100%;
    display: flex;
    margin-top: 24px;
    flex-direction: row;
    justify-content: center;
}

.add-folder-btn-cancel {
    text-transform: capitalize !important;
    border: 1px solid #568200 !important;
    margin: 0px 5px !important;
    padding: 8px 16px !important;
}

.add-folder-btn-save {
    margin: 0px 5px !important;
    text-transform: capitalize !important;
}