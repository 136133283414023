.layout-wrapper {
    flex: 1;
    display: flex;
    padding: 0 24px;
    flex-wrap: nowrap;
    // margin-top: 60px;
    flex-direction: column;
}

@media only screen and (max-width: 768px) {
    .layout-wrapper {
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
    }
}