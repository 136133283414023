.tableContainer {
    // max-height: 600px !important;
}

.itemlist-table-header {
    font-size: .8em;
    font-weight: bold;
    min-height: 44px;
    max-height: 44px;
    display: flex;
    align-items: center;
    float: right;
}

.cell-name-bold {
    font-weight: bold !important;
}

.cell-id-hidden {
    display: none !important;
}

.cell-value {
    font-size: .8em !important;
    color: rgba(81, 81, 81, 0.87) !important;
}

.cell-link {
    color: #000;
}

.header-right-align {
    // display: flex !important;
    // justify-content: flex-end;
    padding-right: 3rem !important;
    // float: right;
}

.right-align {
    justify-content: flex-end;
}

.item-menu-list-item {
    font-size: 16px;
    padding: 6px 35px 6px 20px !important;
}

.pending-icon {
    width: 1em;
    height: auto;
}

.tableCellContainer, .tableCellContainerHidden {
    display: flex;
    flex-direction: column;

    .topCell {
        display: flex;
        flex-direction: row;

        .cellSection {
            margin-right: 0.8em;
        }
    }

    .bottomCell {
        display: none; 
    }

    .cellSection {
        margin-right: .5em;
        display: flex !important;
        align-items: center;
    }

    .cellBoldSection {
        font-size: 16px;
        font-weight: 800;
        margin-right: .5em;
        display: flex !important;
        align-items: center;

        a {
            font-size: 14px;
        }
    }
}

.itemlist-table-header-cell {
    color: rgba(81, 81, 81, 0.87) !important;
}

.itemlist-table-header-cell:first-child {
    width: 50%;
}

.item-ation-btn-cell {
    display: flex !important;
    justify-content: flex-end !important;
    position: relative;

    .action-menu {
        min-width: 170px;
        position: absolute;
        top: 45px;
        right: 30px;
        z-index: 99;
        background-color: white;
        padding: 12px 0;
        box-shadow: 
            0px 5px 5px -3px rgba(0,0,0,0.2), 
            0px 8px 10px 1px rgba(0,0,0,0.14), 
            0px 3px 14px 2px rgba(0,0,0,0.12) !important;

            .action-menu-item {
                font-size: 15px;
                padding: 5px 25px;
                cursor: pointer;
                background-color: white;
                transition: all ease .5s;
            }

            .action-menu-item:hover {
                background-color: #568200;
                color: white;
                transition: all ease .2s;
            }
            
    }
}

.loadingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49px;
}

.loadingContext {
    color: #8a8a8a;
    font-weight: 700;
    font-size: 11px;
    margin-left: 10px;
}


@media only screen and (max-width: 1024px) {
    .cell-value {
        display: none !important;
    }

    .itemlist-table-header-cell {
        display: none !important;
    }

    .itemlist-table-header-cell:first-child, .itemlist-table-header-cell:last-child {
        display: block !important;
        width: 100%;
    }

    .bottomCell {
        display: flex !important;
        flex-direction: row;
    }

    .tableCellContainerHidden {
        display: none !important;
    }
}

