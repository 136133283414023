.disclosure-root {
    border-top: 1px solid #cccccc;
    font-family: "Fidelity Sans",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    @media (max-width: 512px) {
      font-size: 12px;
      line-height: 18px;
    }
    color: #666666;
  
    .disclosure-wrapper {
      margin-top: 24px;
    }
    .address-wrapper {
      margin-top: 16px;
    }
    .eReview-wrapper {
      margin-top: 16px;
    }
  }
  