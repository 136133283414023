/* @import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500); */
/* @import url('https://fonts.googleapis.com/css?family=Lato:300,400|Open+Sans:300,400,500&display=swap'); */
*:focus {
	outline: none;
}

#container {
	position: relative;
	display: block;

}
#login-box {
	position: relative;
	margin: 5% auto;
	width: 300px;
	height: 400px;
	background: rgb(255, 247, 247);
	border-radius: 2px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.left {
	position: absolute;
	top: 0;
	left: 0;
	box-sizing: border-box;
	padding: 40px;
	width: 300px;
	height: 400px;
}

h1 {
	margin: 0 0 20px 0;
	font-weight: 300;
	font-size: 28px;
}



a {
  text-decoration: none;
}

.forgotPassword {
  margin-right: -127px;
  font-size: 12px;
}

#link {
    align-items: center;
    flex:1;
    justify-self: center;
    width: 100%;
    text-align: center;
    padding-top: 50%;
}

#heading {
    display:flex;
    letter-spacing: 8;
    height: 50%;
    flex-direction: row;
    justify-content: center ;
    align-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
    
    
}