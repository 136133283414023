.topic-detail-appbar {
  background-color: white !important;
  border-bottom: 1px solid #c5c5c5;
  box-shadow: none !important;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  color: #333;
  margin-top: 1rem;
  .total-count-label-wrapper {
    color: rgba(0, 0, 0, 0.87);
    flex-grow: 1;
    .total-count-label {
      text-align: start;
      padding-left: 1rem;
    }
  }
}

.topic-detail-disclaimer {
  margin: 0 !important;
}

.MuiTablePagination-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: end;
  flex-wrap: wrap !important;
}

.MuiTablePagination-root {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .MuiTablePagination-toolbar {
  flex-direction: row;
  justify-content: start;
  }
}

.topic-detail-actions {
  padding-right: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconBtn-wrapper {
  width: 44px;
  height: 44px;
}

.iconBtn {
  width: 20px;
  height: 20px;
  color: #627282;
}

.disclaimer-msg {
  color: #000;

  a {
    color: #1565c0;
  }
}
.loaderholder {
  left: 50%;
  top: 50%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  align-items: center;
  justify-content: center;
}
.loadingtext {
  margin: 1rem;
  font-size: 0.9rem;
}
