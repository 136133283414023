.page-wrapper {
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F0F0F0;
    z-index: 1300;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
}

.main-window {
    flex: 1 auto;
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.menu-wrapper-open {
    background-color: white;
    width: 340px;
    min-width: 340px;
    height: 100%;
    box-shadow: -5px 0px 5px rgba(0,0,0,0.14);
    transition: all 0.2s linear;
    opacity: 1;
    height: auto;
    overflow: auto !important;
    z-index: 1609;
}

.menu-wrapper-close {
    opacity: 0;
    width: 0;
    transition: all 0.2s linear;
}

.bottom-action-sheet {
    width: calc(100% - 218px);
    padding: 2rem 109px 2rem 109px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .action-description {
        flex: 1 auto;
        max-width: 1300px;
    }

    .action-btns-wrapper {
        min-width: 200px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        align-items: center;
        
        .btn-decline {
            margin-right: 2rem;
        }

        .btn-agree {

        }
    }
}


@media only screen and (max-width: 767px) {
    .main-window {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .menu-wrapper-open {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        min-width: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    .bottom-action-sheet {
        width: calc(100% - 2rem);
        flex-direction: column;
        padding: 1rem;

        .action-description {
            max-width: 100%;
            margin-bottom: 1rem;
        }
    
        .action-btns-wrapper {
            width: 100%;
        }
    }
}
