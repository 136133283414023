.page-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F0F0F0;
    z-index: 1300;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

.main-window {
    flex: 1 auto;
    background-color: #F0F0F0;
}

.menu-wrapper-open {
    background-color: white;
    width: 440px;
    height: 100%;
    box-shadow: -5px 0px 5px rgba(0,0,0,0.14);
    transition: all 0.2s linear;
    opacity: 1;
    height: auto;
    overflow: hidden;
}

.menu-wrapper-close {
    opacity: 0;
    width: 0;
    transition: all 0.2s linear;
}

@media only screen and (max-width: 767px) {
    .main-window {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .menu-wrapper-open {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}
