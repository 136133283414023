.prod-menu-list {
    width: 100%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.prod-menu-header {
    background-color: #f8f8f8 !important;
    height: 64px;
}

.prod-menu-title {
    font-family: 'Fidelity Sans Bold';
    text-transform: uppercase;
    color: #666;
    padding-left: 12px;
}

.prod-menu-header:hover {
    color: #000 !important;
}

.prod-list-item {
    padding-left: 3rem !important;
    height: 64px;
}

.menu-selected {
    background-color: #f1f1f1 !important;
}

.menu-selected:hover {
    background-color: #dddddd !important;
    color: #000 !important;
}

@media only screen and (max-width: 600px) {
    .prod-menu-header {
        height: 64px;
    }

    .prod-list-item {
        height: 48px;
    }
}