.contactList-wrapper {
    margin: 1em 0;
}

.tabs-wrapper {
    width: 100%;
    box-shadow: none !important;
}

.contact-list-tabs {
    background-color: white;
}

.contact-list-no-data-wrapper {
    padding: 1em 30px;
}

.tab-btn {
    padding: 35px 12px 12px 12px !important;
}

.tabs-appbar {
    border-bottom: 2px solid #ccc;
    box-shadow: none !important;
}

.tab-label {
    color: #3376a0;
    font-weight: bold; 
    font-size: 14px;
}

.Mui-selected > .MuiTab-wrapper > .tab-label {
    color: #474747;
    font-weight: bold; 
}

.indicator {
    height: 6px !important;
}

.MuiBox-root {
    padding: 0 !important;
    width: 100% !important;
}