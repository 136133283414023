.topic-appbar {
    border-bottom: 2px solid #ccc;
    box-shadow: none !important;
    background-color: white !important;
}

.topic-app-bar-wrapper {
    background-color: white !important;
    border-bottom: 1px solid #c5c5c5;
    box-shadow: none !important;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    color: #333;
}

.filter-collapse-wrapper {
    box-shadow: none !important;
    width: 100%;
}

.filter-collapse-details {
    padding: 0 1rem !important;
}

@media only screen and (max-width: 767px) {
    .filter-collapse-details {
        padding: 0 !important;
    }
}

.iconBtn {
    width: '20px';
    height: '20px';
}