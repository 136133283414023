.eSignStartMain {
    line-height: 24px;
    margin-top: 8px;

    a {
        color: #024A7A;
        font-size: 16px;
    }
    .content {
        display: flex;
        padding: 16px 0;
        flex-direction: column as column;
    }
    .modal_subtitle {
        font-size: 16px;
        color: rgba(0, 0, 0, 1);
        width: 100%;
        letter-spacing: 0.15px;
        line-height: 24px;
    }
    .modal_title {
        color: #000000;
        font-size: 1.65rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32.5px;
    }
    .disclosure_title {
        color: #000000;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32.5px;
    }
    .commonModalActions {
        justify-content: center;
        padding: 0px;
        display: grid;
        grid-template-columns: 1fr 2fr;

        a {
            color: #568200;
        }
    }
    .primarybtn {
        text-transform: none;
        width: 170px;
    }
    .documentLinkCancel {
        font-size: 1rem;
        padding-left: 8px;
        display: inline-block;
        left: 2rem;
    }
    .iconBtn {
        width: 16px;
        height: 16px;
        margin-left: -2px;
        margin-bottom: 3px;
        vertical-align:middle;
    }
    .modal_footer {
        font-size: 14px;
        color: #666666;
        width: 100%;
        letter-spacing: 0.15px;
        padding-bottom: 16px;
        line-height: 21px;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: #cccccc;
        padding-top: 24px;
    }
    .disclosure {
        max-height: calc(100vh - 8rem);
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 16px;
        padding: 0 1rem;
    }
    .eReview  {
        font-size: 14px;
        color: #666666;
        width: 100%;
        letter-spacing: 0.15px;
        padding-bottom: 16px;
        line-height: 21px;
    }
}