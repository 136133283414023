.common-modal-typography {
    display: flex;
    align-items: center;
}

.alert-icon {
    margin-right: 10px;
}

.common-modal-title {
    font-size: 24px !important;
    padding: 0 !important;
}

.common-modal-actions {
    justify-content: center !important;
    padding: 0 !important;
}

.common-modal-button {
    font-size: 14px !important;
    padding: 12px 45px !important;
    text-transform: capitalize !important;
    border-color: #568200 !important;
    font-family: 'Fidelity Sans';
}

.dialog-title {
    font-size: 24px !important;
}

.common-modal-description-root {
    padding: 25px 0 !important;
}

.common-modal-description {
    color: black !important;
    font-weight: bold;
    margin: 0;
}

.description-content {
    line-height: 1;
    font-weight: 500;
}