.main-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.top-action-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;

    .action-title {
        display: flex;
        align-items: center;

        .action-title-text {
            width: 308px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .typeIcon {
        margin-right: 1rem;
    }

    .iconBtn {
        width: 20px;
        height: 20px;
    }
}

.preview-window {
    display: flex;
    justify-content: center;
    align-items: center;
}