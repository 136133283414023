.side-drawer { 
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    z-index: 200;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
 }
 .side-drawer.open {
    transform: translateX(0);
 }

 .swiperdrawer {
   height: 800; 
   width: 400;
   background-color: 'white';
   z-index: 999;
   top: 64;
   right:0;
   border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
 }

.swipeable-list-item {
   background-color: white !important;
}

.swipeable-list-item:hover, .swipeable-list-item:active {
   background-color: white !important;
}

.panel-title-wrapper {
   padding: 0.5rem 0 0.5rem 1rem;
   background-color: rgba(179, 179, 179, 0.12);
   border-bottom: 1px solid rgb(221, 221, 221);
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
}

.panel-title {
   margin: 0;
}