.topic-appbar {
    border-bottom: 2px solid #ccc;
    box-shadow: none !important;
    background-color: white !important;
}

.topic-app-bar-wrapper {
    background-color: white !important;
    border-bottom: 1px solid #c5c5c5;
    box-shadow: none !important;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    color: #333;
    margin-top: 1rem;
    .total-count-label-wrapper {
        color: rgba(0, 0, 0, 0.87);
        flex-grow: 1;
        .total-count-label {
            text-align: start;
            padding-left: 1rem;
        }
    }
}

.topic-app-bar-wrapper-no-topics {
    margin-top: 1rem;
    position: relative;
    display: flex;
    justify-content: flex-start;
    background-color: #fff !important;
    padding: 8px 13px;
    box-shadow: 0 3px 6px rgba(0, 0, 0,0.16), 0 3px 2px rgba(0, 0, 0,0.23) !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

.filter-collapse-wrapper {
    box-shadow: none !important;
    width: 100%;
}
.totalCountLabel {
    color: rgba(0, 0, 0, 0.87);
    margin: auto;
    font-size: .0.875rem;
}
.filter-collapse-details {
    padding: 0 1rem !important;
}

.expansion-panel {
    background-color: #fff !important;
    padding: 16px 10px 16px 0px; 
}
.progressbar {
    height: 4px
}

@media only screen and (max-width: 767px) {
    .filter-collapse-details {
        padding: 0 !important;
    }
}