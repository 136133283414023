.uploader-wrapper {
   background-color: white;

   .uploader {
       padding: 1rem;
       border: 2px dotted #ccc;
       text-align: center;
       transition: all 0.2s ease;
       cursor: pointer;
   }

   .uploader-form {
       display: flex;
       flex-direction: row;
       justify-content: center;
       align-items: center;

       .uploader-label {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
       }

       .browse-btn {
           margin: 0 0.5rem;
           text-transform: capitalize;
           padding: 4px 2rem;
       }
   }

   .uploader-on-hover {
        padding: 1rem;
        border: 2px dotted #000;
        text-align: center;
        transition: all 0.2s ease;
        background-color: #fafafa;
   }

   .uploader-link {
        color: blue;
        text-decoration: underline;
        font-size: 1rem;
        cursor: pointer;
    }
}