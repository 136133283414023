.pv-drawer-wrapper {
    margin-top: 1rem;
}

.add-btn {
    margin-left: 0.5rem !important;
    padding-right: 1.5rem !important;
    font-weight: 700 !important;
    margin-bottom: 0.5rem !important;
    height: 64px !important;
    border-radius: 5rem !important;
}

.back-btn {
    font-weight: 700 !important;
    width: calc(100% - 1.5rem);
    height: 64px;
    padding-left: 1.5rem;
    margin-bottom: 8px;
    overflow: hidden;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(86,130,0,0.16), 0 3px 6px rgba(86,130,0,0.23);
}

.action-icon {
    margin-right: 1.5rem;
}

.menu-wrapper {
    position: relative;
}

.drawer-form {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
}