.menu-wrapper {
    position: relative;
    overflow-y: auto;
    height: 100vh;

}

.menu-close-btn {
    top: 5px;
    right: 5px;
}

.tabs-wrapper {
    width: 100%;
    box-shadow: none !important;
}

.tab-wrapper {
    padding: 0 1rem;
}

.tab-label {
    color: #3376a0;
    font-weight: bold;
    font-size: 14px;
}

.Mui-selected>.MuiTab-wrapper>.tab-label {
    color: #474747;
    font-weight: bold;
}

.info-block {
    padding-bottom: 1rem;
    color: #474747;
}

.info-block-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .editBtn {
        color: #8c8c8c;
        margin: 0 .2rem;
        cursor: pointer;
        transition: all ease 0.2s;
    }

    .editBtn:hover {
        color: #333;
    }
}

.info-title {
    color: #474747;
    margin: 1rem 0;
    display: flexbox;
}

.info-table {
    width: 100%;
    font-size: 14px;

    .info-table-cell {
        width: 50%;
        padding: 0.3rem 0;
    }

    .info-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem 0;
    }

    .info-contact-name {
        color: #000;
    }
}

.infoHeader {
    font-size: 0.8rem;
    font-weight: bold;
    padding-bottom: 0.3125rem;
}

.infoValue {
    width: 308px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .8rem;
    padding-bottom: 0.3125rem;
}

.infoValueActivity {
    width: 308px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .8rem;
    padding-bottom: 0.3125rem;
}

.infoDateValue {
    font-size: .9rem;
    color: grey;

}

.infoData {
    padding: 0.3rem 0;
}

.menu-heading {
    font-size: 1.3rem;
    font-weight: bold;
    padding: 1rem;
}

.activity-content {
    position: relative;
    left: 0;
    min-height: 100px !important;
    right: 0;
    z-index: -1;
}

.errorMessage {
    color: red;
    font-size: .8rem;
    ;
}

.file-header {
    display: block;
    background-color: white;
    position: sticky;
    top: 0;
}