.filter-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .filter {
        margin: 0 1rem;
        font-size: 14px !important;
        width: calc(100% - 2rem);

        .filter-input {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 767px) {
    .filter-wrapper {
        flex-direction: column;
        align-items: flex-start;
        font-size: 14px;
    
        .filter {
            margin-bottom: 1rem;
        }

        .reset-btn {
            width: 100%;
            margin-left: 0;
        }
    }
}
