.menu-wrapper {
    position: relative;
}

.menu-close-btn {
    position: absolute !important;
    top: 5px;
    right: 5px;
    z-index: 1;
}

.tabs-wrapper {
    width: 100%;
    box-shadow: none !important;
}

.file-detail-tabs {
    background-color: white;
}

.file-detail-tab-btn {
    padding: 25px 12px 12px 12px !important;
}

.menu-appbar {
    border-bottom: 2px solid #ccc;
    box-shadow: none !important;
}

.tab-wrapper {
    padding: 0 1rem;
}

.tab-label {
    color: #3376a0;
    font-weight: bold; 
    font-size: 14px;
}

.Mui-selected > .MuiTab-wrapper > .tab-label {
    color: #474747;
    font-weight: bold; 
}

.indicator {
    height: 6px !important;
}

.info-block {
    padding-bottom: 1rem;
    color: #474747;
}

.info-block-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .editBtn {
        color: #8c8c8c;
        margin: 0 .2rem;
        cursor: pointer;
        transition: all ease 0.2s;
    }

    .editBtn:hover {
        color: #333;
    }
}

.info-title {
    color: #474747;
    margin: 1rem 0;
}

.info-table {
    width: 100%;
    font-size: 14px;

    .info-table-row {
        
    }

    .info-table-cell {
       width: 50%;
       padding: 0.3rem 0;
    }

    .info-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem 0;
    }

    .info-contact-name {
        color: #000;
    }
}

