$width: 351px;
$transitionWidth: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms; 
$transitionMargin: margin 195 cubic-bezier(0.4, 0, 0.6, 1) 0ms; 

.main-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(135deg, #f4f4f4 40%, #f9f9f9 40%, #f9f9f9 50%, #f4f4f4 50%, #f4f4f4 90%, #f9f9f9 90%, #f9f9f9)
}

.drawer {
    width: $width;
    flex-shrink: 0;
    white-space: nowrap;
    position: relative;
}

.toolbar {
    display: flex;
    padding: 0 5px !important;
    justify-content: space-between;
}

.toolbar-left {
    display: flex;
    flex-direction: row;
}

.drawer-toolbar {
    min-height: 64px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 8px;
}

.drawer-open {
    width: $width;
    transition: $transitionWidth !important;
}


.drawer-close {
    transition: $transitionWidth !important;
    overflow: hidden;
    width: 73px;
}

.app-bar {
    z-index: 1300 !important;
    transition: $transitionWidth !important;
    transition: $transitionMargin !important;
    position: fixed !important;
    top: 0;
    left: 0;
}

.toolbar-title {
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-size: 18px;
}

.app-title {
    margin-left: 14px;
    padding-left: 14px;
    border-left: 1px solid white;
}

.list-icon {
    padding-left: 8px;
    min-width: 50px !important;
}

.prod-list-title-wrapper {
    p {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.prod-main-title {
    font-size: 20px;
}

.prod-sub-title {
    font-family: 'Fidelity Sans Bold';
    text-transform: uppercase;
    color: #666;
}

.spacer {
    flex: 1 1 auto;
}

.search-wrapper {
    position: relative;
    border-radius: 5px;
    background-color: rgba($color: #fff, $alpha: .35);
    margin-right: 1rem;
    margin-left: 0;
    width: 100%;
    flex: 1 auto;
    max-width: 40%;
    overflow: hidden;
    display: flex;
    transition: all ease 0.2s;
}

.search-wrapper:hover {
    background-color: rgba($color: #fff, $alpha: .45);
}

.search-icon {
    padding: 0 1rem;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clear-icon {
    padding: 0 .5rem;
    height: 100%;
    position: absolute;
    right: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-input {
    color: inherit;
    // padding: 0.5rem 0.5rem 0.5rem 3rem;
    padding: 0.2rem;
    transition: all ease 0.2s;
    width: 100%;
    font-weight: 700;
    color: white !important;
    margin: 0 2rem 0 3rem;
    flex: 1 auto;
}

.prod-select-drawer-wrapper {
    position: absolute;
    width: 100%;
    height: 500px;
    background-color: white;
    z-index: 9999;
    top: 146px;
    left: 0;
}

.menu-button {
    margin: 0 5px 0 8px !important;
}

.menu-item {
    color: #000;
    transition: all 0.2s ease;
    height: 64px;
}

.menu-item:hover {
    background-color: #f4f4f4 !important;
    color: #000 !important;
}

.menu-active {
    background-color: #dedede !important;
    // color: #fff !important;
    transition: all 0.2s ease;
}

.hide {
    display: none !important;
}

.content-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1 auto;
    margin-top: 64px;
} 

.content {
    // margin-top: 64px;
    flex: 1 auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.mask-layer {
    background-color: rgba($color: #000000, $alpha: 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.logo-margin-panel {
    height: 56px;
}

.menu-link {
    padding: 1.5em;
}

.port-switch-wrapper-open {
    padding: 1.5rem;
}

.port-switch-wrapper-close {
    padding: 13px;
}

.old-port-link {
    color: #9b28af;
    cursor: pointer;
}

.common-swipeable-drawer {
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

@media only screen and (max-width: 600px) {
    .drawer-toolbar {
        min-height: 56px;
    }

    .drawer {
        position: fixed;
        z-index: 999;
    }

    .drawer-toolbar {
        min-height: 112px;
    }

    .drawer-close {
        width: 0;
    }

    .content-wrapper {
        // overflow: hidden;
    }

    .prod-select-drawer-wrapper {
        top: 194px;
    }

    .content-wrapper {
        margin-top: 56px;
    } 
}
