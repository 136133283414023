.time-out-wrapper {
    max-width: 450px;
}

.time-out-action-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
}

.time-out-label-1 {
    margin-top: 0;
}

.time-out-label-2 {
    margin-bottom: 0;
}

.logout-link {
    color: #0978A4 !important;
    cursor: pointer;
}

.logout-link:hover {
    text-decoration: underline;
}

.non-warning-label {
    margin-left: 0.4rem;
    color: #000;
}

.warning-label {
    margin-left: 0.4rem;
    color: #d20000;
    font-weight: 700;
}

.logout-wrapper {
    position: absolute;
    left: 0;
}
