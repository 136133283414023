.feedback-wrapper {
    transform: rotate(-90deg);
    position: fixed;
    top: 50%;
    right: -35px;
    background-color: white;
    border: 1px solid #888;
    border-radius: 0.2rem;
    padding: 0px 0.9rem 5px 0.9rem;
    z-index: 99;
}

.feedback-label {
    color: #888 !important;
    font-weight: 700;
}