.breadcrumb-wrapper {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.add-topic-btn {
    text-transform: capitalize !important;
}
