.main-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // // overflow-x: hidden;
    // overflow-y: auto;
    overflow: auto;
}

.note-action-bar {
    display: flex;
    position: fixed;
    top: 80px;    
    background-color: #333638;
    color: white;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    z-index: 99;
    padding: 0rem 1rem 1rem 28px;
    align-items: center;
}

.note-action-bar-message {
    margin-left: 15px;
}

.btn-icon-info {
    color: white;
    margin-right: 8px;
}

.top-action-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #333638;
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding: 0 1rem;
    position: fixed;
    top: 0;
    width: calc(100% - 2rem);
    z-index: 99;

    .action-title {
        display: flex;
        flex-direction: row;
        align-items: center;

        .typeIcon {
            margin-right: .5em;
        }

        .action-title-text {
            width: 308px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .btn-icon {
        color: white;
    }

    .btn-more-icon {
        color: white;
    }
}

.preview-window {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-direction: column;
    padding: 0 1rem;
    background-color: #505050;
    margin-top: 72px;
    &.note-action-bar-present {
        margin-top: 112px;
    }
}

.iframe {
    min-height: 800px;
    width: 95%;
}

.file-container {
    width: 100%;
    height: 100%;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    background-color: #505050;
}

.pdfdocument{
    width: 100%;
    text-align: center;
    align-items: center;
    color: #fff;
    margin-top: 1.563rem;
    margin-bottom: 1.25rem;
    max-width: 100%;
    // overflow-x: hidden;
    .react-pdf__Page {
         display: flex;
         justify-content: center;
         margin-top: 1.25rem;
        .react-pdf__Page__canvas {
            width: 100% !important;
            max-width: 920px;
            height: 100% !important;
        }
    }
}

.image-preview {
    height: 730px;
    max-width: 1024px;
    text-align: center;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
}

.toolbar_preview {
    display: flex;
    padding: 0 5px 20% 5px;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    width: 100%;
}

.preview-error-msg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.preview-img-wrapper {
    width: 100%;
}


.preview-MenuItem {
    color: #000;
    transition: all 0.2s ease;
}

.preview-MenuItem:hover {
    background-color: #cfc8c8 !important;
    color: #000 !important;
}

@media only screen and (max-width: 767px) {
    .top-action-bar {
        display: flex;
        flex-direction: column;

        .action-btns {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    .preview-window {
        padding: 0;
        margin-top: 80px;
        height: calc(100% - 80px);
        &.note-action-bar-present {
            margin-top: 120px;
        }
    }
}