.share-file-tabs {
    background-color: white !important;
    border-bottom: 1px solid #c5c5c5;
    box-shadow: none !important;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
}

.share-file-actions {
    padding-right: .5rem;
    display: flex;
    flex-direction: row;
}

.tab-btn {
    padding: 35px 12px 12px 12px !important;
}

.indicator {
    height: 6px !important;
}

.pending-label {
    color: rgba(0, 0, 0, 0.38);
    font-weight: bold; 
}

.tab-label {
    color: #3376a0;
    font-weight: bold; 
}

.Mui-selected > .MuiTab-wrapper > .tab-label {
    color: #474747;
    font-weight: bold; 
}

.iconBtn {
    width: 20px;
    height: 20px;
    color: #627282;
}

.iconBtn-wrapper {
    width: 44px;
    height: 44px;
}

.no-file-block {
    background-color: white !important;
    border-bottom: 1px solid #c5c5c5;
    padding: 1rem;
    height: calc(71px - 2rem);
    display: flex;
    align-items: center;
}