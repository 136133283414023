.root {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 1rem;
}

.backBtnIcon {
  color: #0978a4;
}

.linkContainer {
  font-size: 15px;
  display: flex;
  align-items: center;
}

.profileContainer {
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
}

.TopicDetailleftIcon {
  display: flex;
  align-items: flex-start;
}

.TopicDetailleftSection {
  display: flex;
  align-items: flex-start;
  width: 67%;
  height: 100%;
}

.topicProfileCardIcon {
  border: 2px solid #ccc;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  padding: 2px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.nameContainer {
  margin: 0 0.8em;
}

.flexWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 auto;
  margin-left: 1em;
}

.topicNameContainer {
  z-index: 99;
}

.topicNameWrapper {
  margin: 0 0 0.25em 0;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.TopicName {
  // max-width: 50%;
  margin: 0;
  overflow-wrap: break-word !important;
  word-wrap: break-all !important;
  hyphens: auto !important;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
}

.memberWrapper {
  color: #353636;
  margin: 0.5em 0;
  font-size: 13px;
}

.contacts-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.showContacts {
  color: #353636;
  margin-left: 0.1rem;
}

.see-more-tag {
  color: #0978a4;
  cursor: pointer;
  height: 16px;
}

.less-contacts {
  margin-left: 0.3rem;
}

.showContactsWrapper {
  display: inline-block;
  vertical-align: middle;
}

.memberLabel {
  color: #353636;
  font-weight: 800;
  font-size: 13px;
  margin-right: 0.5em;
}

.TopicCreatedTime {
  margin: 0;
  font-size: 13px;
  letter-spacing: 0.1px;
  color: #353636;
  margin-bottom: 8px;
}

.contactLabel {
  margin-right: 0.5em;
  display: flex;
  align-items: flex-end;
}

.contactLabel:nth-last-child(2) {
  margin-right: 0 !important;
}

.emailLink {
  color: #0978a4;
  text-decoration: underline;
}

.editBtn {
  width: 20px !important;
  height: 20px !important;
  margin-left: 0.5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  // color: #8c8c8c;
  border: none;
  background-color: white;
  cursor: pointer;
  transition: all ease 0.2s;
  &:hover {
    color: #333;
  }
}

.followBtnDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.topicDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 33%;
  height: 100%;
  text-align: right;
  align-items: flex-end;
}

.removeTopicBtn {
  color: #0978a4;
  font-size: 15px;
}

@media only screen and (max-width: 767px) {
  .flexWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .topicDetailsContainer {
    width: 100%;
    align-items: flex-start;
  }
  .leftIcon {
    align-items: flex-start;
  }
  .leftSection {
    width: 100%;
  }

  .TopicDetailleftSection {
    width: 100%;
  }

  .followbtn {
    width: 100% !important;
  }
}
