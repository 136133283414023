.contacts-searchbar-wrapper {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 440px;
    margin: 1rem 16px;
    padding: 9px 0 9px 9px;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 5px;
    position: relative;
    letter-spacing: .2px;
    margin-top: 1rem;

    .searchbar-results-wrapper {
        flex: 1 auto;
        display: flex;
        flex-direction: column;

        .result-content-wrapper {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-start;
            
            .searchbar-input {
                font-size: 16px;
                padding: 9px 4px 9px 5px;
                border: none;
                flex: 1 auto;
                letter-spacing: 1px;
            }
    
            .result-chip {
                margin: 0 5px 5px 5px;
            }

            .search-clear-btn {
                cursor: pointer;
                width: 25px;
                height: 25px;
                color: rgb(151, 151, 151);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                margin-top: 5px;
                margin-right: 5px;
                transition: all ease 0.3s;
                position: absolute;
                top: 10px;
                right: 30px;
            }

            .close-icon {
                width: 20px;            }

            .search-clear-btn:hover {
                cursor: pointer;
                color: rgb(151, 151, 151);
                background-color: rgb(233, 233, 233);
                transition: all ease 0.3s;
            }
        }

        .result-positioner {
            position: relative;
            max-height: 300px;
            // overflow: auto;

            .search-contact-panel {
                position: absolute;
                top: 15px;
                left: -10px;
                z-index: 99;
                width: 450px;
                background-color: #fff;
                border: 1px solid rgb(180, 180, 180);
                border-radius: .5rem;
                max-height: 350px;
                overflow: auto;
            }
        }

        .search-item:hover {
            color: #000;
            background-color: #f2f2f2 !important;
        }

        .loading-item-row {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
        }

        .loading-item-row:hover {
            background-color: #f2f2f2 !important;
        }
    }

    .searchbar-icon-wrapper {
        width: 24px;
        padding-top: 7px;
        color: rgb(119, 119, 119);
        margin-right: 9px;
    }

    .placeholder-label-normal {
        // color: rgb(119, 119, 119);
        display: none;
    }

    .placeholder-label-top {
        font-size: 12px;
        position: absolute;
        top: -10px;
        background-color: #fff;
        padding: 0 5px;
        color: rgb(90, 90, 90);
    }
}

.contacts-searchbar-wrapper-onFocus {
    border: 2px solid #568200;

    .placeholder-label-top {
        color: #568200;
    }
}