.add-topic-textfield {
    width: 90% !important;
    height: 56 !important;
    margin: 1rem 0 0 1rem !important;
}

.add-topic-modal-actions {
    display: flex;
    justify-content: center !important;
    padding: 0 !important;
    margin-top: 10px !important;
    align-items: center;
}

.topic-btn-cancel {
    font-size: 14px !important;
    border: 1px solid #e0e0e0 !important;
    font-family: 'Fidelity Sans';
    padding: 8px 12px !important;
    text-transform: capitalize !important;
    margin-right: 8px !important;
}

.topic-link-cancel {
    position: absolute;
    left: 2rem;
}

.helper-text-wrapper {
    margin-top: 7px;
}

.topic-helper-text {
    margin: 0 28px;
    font-size: 12px;
    color: #F44336;
}
