.root {
    background-color: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.backBtnIcon {
    color: #0978A4
}

.linkContainer {
    font-size: 15px;
    display: flex;
    align-items: center
}

.profileContainer {
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
}

.leftIcon {
    display: flex;
    align-items: center;
    // [theme.breakpoints.down(xs)] {
    //     align-items: flex-start;
    //     marginTop: .25em
    // }
}

.leftSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 auto;
    height: 100%;
}

.contactProfileCardIcon {
    border: 2px solid #000;
    border-radius: 4px;
    color: #000;
    padding: 2px;
    font-size: 15px;
    font-weight: 700;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nameContainer {
    margin: 0 .8em;
    // maxWidth: 320px
}

.flexWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex: 1 auto;
    margin-left: 1em;
}

.contactName {
    margin: 0 0 .25em 0;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center
}

.login-stamp-wrapper {
    // color: #0978A4;
    // margin: .5em 0;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.memberLabel {
    color: #353636;
    font-weight: 800;
    font-size: 13px;
    margin-right: 0.5em;
}

.last-login-label {
    color: #353636;
    font-weight: 800;
    font-size: 14px;
    margin-right: 0.5em;
}

.contact-meta-data {
    margin: 0;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #353636;
    line-height: 1.5;
}

.contactLabel {
    margin-right: 0.5em;
    display: flex;
    align-items: flex-end;
    color: #353636;
}

.contactLabel:nth-last-child(2) {
    margin-right: 0 !important;
}

.loginLabel {
    display: flex;
    align-items: flex-end;
    color: #353636;
}

.emailLink {
    color: #353636;
    text-decoration: underline
}

.editBtn {
    width: 20px !important;
    height: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem !important;
    color: #8c8c8c;
    cursor: pointer;
    transition: all ease 0.2s;
    &:hover {
        color: #333;
    }
}

.detail-right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    line-height: 1.5;
    // width: 40%;
    text-align: right;
}

.removecontactBtn {
    color: #0978A4;
    font-size: 15px
}

@media only screen and (max-width: 767px) {
    .flexWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .detail-right-container {
        align-items: flex-start;
        width: 100 !important;
    }

    // .profileContainer {
    //     padding: 8px 24px;
    //     display: flex;
    //     flex-direction: row;
    // }
}
