.contact-list-wrapper {
    margin-top: 1rem;
    position: relative;
}

.contact-filter-wrapper {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    background-color: #fff !important;
    padding: 8px 13px;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1), 0 -1px 1px rgba(0, 0, 0, 0.1) !important;
    border-bottom: 1px solid #c5c5c5;
    .total-count-label-wrapper {
        color: rgba(0, 0, 0, 0.87);
        flex-grow: 1;
        .total-count-label {
            text-align: end;
            margin-block-start: 1rem;
            margin-right: 1rem;
        }
    }
}

.loading-item-row {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.search-text-field {
    width: 50%;
    max-width: 350px;
    margin: 0 !important;
    
    // #contact-filter {
    //     padding-top: 10px;
    //     padding-bottom: 10px;
    // }
}

.search-result-panel {
    position: absolute;
    top: 70px;
    left: 13px;
    max-height: 350px;
    overflow: auto;
    z-index: 2;
    background-color: white;
    border-radius: 5px;
    width: 50%;
    max-width: 350px;
    box-shadow: 0 3px 6px rgba(0, 0, 0,0.16), 0 3px 2px rgba(0, 0, 0,0.23) !important;
}

.search-item:hover {
    color: #000;
}

.search-item-active {
    background-color: #f2f2f2 !important;
}

.search-item-active:hover {
    color: #000 !important;
}


@media only screen and (max-width: 600px) {
    .search-text-field {
        width: 100%;
    }

    .search-result-panel {
        width: calc(100% - 25px);
    }
}
