@font-face {
  font-family:'Fidelity Sans';
  src:url(https://assets.fidelity.com/fonts/FidelitySans-Regular.woff2) format("woff2"),url(https://assets.fidelity.com/fonts/FidelitySans-Regular.woff) format("woff");
}
@font-face {
  font-family:'Fidelity Sans Italic';
  src:url(https://assets.fidelity.com/fonts/FidelitySans-Italic.woff2) format("woff2"),url(https://assets.fidelity.com/fonts/FidelitySans-Italic.woff) format("woff");
}
@font-face {
  font-family:'Fidelity Sans Bold';
  src:url(https://assets.fidelity.com/fonts/FidelitySans-Bold.woff2) format("woff2"),url(https://assets.fidelity.com/fonts/FidelitySans-Bold.woff) format("woff");
}
@font-face {
  font-family:'Fidelity Sans Bold Italic';
  src:url(https://assets.fidelity.com/fonts/FidelitySans-BoldItalic.woff2) format("woff2"),url(https://assets.fidelity.com/fonts/FidelitySans-BoldItalic.woff) format("woff");
}
@font-face {
  font-family:'Fidelity Sans Extra Bold';
  src:url(https://assets.fidelity.com/fonts/FidelitySans-ExtraBold.woff2) format("woff2"),url(https://assets.fidelity.com/fonts/FidelitySans-ExtraBold.woff) format("woff");
}
@font-face {
  font-family:'Fidelity Sans Light';
  src:url(https://assets.fidelity.com/fonts/FidelitySans-Light.woff2) format("woff2"),url(https://assets.fidelity.com/fonts/FidelitySans-Light.woff) format("woff");
}
@font-face {
  font-family:'Fidelity Sans Light Italic';
  src:url(https://assets.fidelity.com/fonts/FidelitySans-LightItalic.woff2) format("woff2"),url(https://assets.fidelity.com/fonts/FidelitySans-LightItalic.woff) format("woff");
}
@font-face {
  font-family:'Fidelity Sans Ultra Light';
  src:url(https://assets.fidelity.com/fonts/FidelitySans-UltraLight.woff2) format("woff2"),url(https://assets.fidelity.com/fonts/FidelitySans-UltraLight.woff) format("woff");
}
@font-face {
  font-family:'Fidelity Sans Condensed Medium';
  src:url(https://assets.fidelity.com/fonts/FidelitySansCondensed-Medium.woff2) format("woff2"),url(https://dpcs-assets-fidelity-stage.dpcs.fmr.com/fonts/FidelitySansCondensed-Medium.woff) format("woff");
}


a {
  color: rgb(255, 254, 254);
  font-size: 0.8125rem
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Fidelity Sans",Helvetica,Sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.breadcrumb-item:hover{
  text-decoration: underline !important;
  opacity: 0.9,
}

